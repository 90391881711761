import {cxform, enhanceContext, EnhancedCanvasRenderingContext2D,} from "./canvas";
import allFrames from "./allFrames";

function decode62(n) {
  if (n >= 48 && n <= 58) {
    return n - 48;
  }
  if (n >= 65 && n <= 90) {
    return n - 65 + 10;
  }
  if (n >= 97 && n <= 122) {
    return n - 97 + 36;
  }
  return 63;
}

function getColor(indexColor) {
  if (isDarkPalette) {
    switch (indexColor % 13) {
      case 0:
        return new cxform(0, 0, 0, 0, 77, 77, 77, 256);
      case 1:
        return new cxform(0, 0, 0, 0, 61, 0, 99, 256);
      case 2:
        return new cxform(0, 0, 0, 0, 92, 44, 44, 256);
      case 3:
        return new cxform(0, 0, 0, 0, 36, 74, 66, 256);
      case 4:
        return new cxform(0, 0, 0, 0, 45, 74, 36, 256);
      case 5:
        return new cxform(0, 0, 0, 0, 41, 66, 117, 256);
      case 6:
        return new cxform(0, 0, 0, 0, 41, 51, 87, 256);
      case 7:
        return new cxform(0, 0, 0, 0, 21, 37, 64, 256);
      case 8:
        return new cxform(0, 0, 0, 0, 84, 55, 14, 256);
      case 9:
        return new cxform(0, 0, 0, 0, 75, 39, 0, 256);
      case 10:
        return new cxform(0, 0, 0, 0, 92, 44, 44, 256);
      case 11:
        return new cxform(0, 0, 0, 0, 92, 78, 44, 256);
      case 12:
        return new cxform(0, 0, 0, 0, 92, 78, 44, 256);
    }
  }

  if (isShinyPalette) {
    switch (indexColor % 13) {
      case 0:
        return new cxform(0, 0, 0, 0, 255, 255, 255, 256);
      case 1:
        return new cxform(0, 0, 0, 0, 213, 217, 225, 256);
      case 2:
        return new cxform(0, 0, 0, 0, 255, 190, 146, 256);
      case 3:
        return new cxform(0, 0, 0, 0, 198, 255, 225, 256);
      case 4:
        return new cxform(0, 0, 0, 0, 12, 255, 200, 256);
      case 5:
        return new cxform(0, 0, 0, 0, 210, 222, 255, 256);
      case 6:
        return new cxform(0, 0, 0, 0, 166, 255, 255, 256);
      case 7:
        return new cxform(0, 0, 0, 0, 144, 255, 255, 256);
      case 8:
        return new cxform(0, 0, 0, 0, 236, 165, 182, 256);
      case 9:
        return new cxform(0, 0, 0, 0, 255, 172, 160, 256);
      case 10:
        return new cxform(0, 0, 0, 0, 255, 168, 255, 256);
      case 11:
        return new cxform(0, 0, 0, 0, 255, 249, 119, 256);
      case 12:
        return new cxform(0, 0, 0, 0, 247, 243, 200, 256);
    }
  }
  switch (indexColor % 13) {
    case 0:
      return new cxform(0, 0, 0, 0, 255, 242, 223, 256);
    case 1:
      return new cxform(0, 0, 0, 0, 255, 204, 121, 256);
    case 2:
      return new cxform(0, 0, 0, 0, 255, 170, 30, 256);
    case 3:
      return new cxform(0, 0, 0, 0, 236, 255, 217, 256);
    case 4:
      return new cxform(0, 0, 0, 0, 203, 255, 151, 256);
    case 5:
      return new cxform(0, 0, 0, 0, 213, 234, 255, 256);
    case 6:
      return new cxform(0, 0, 0, 0, 151, 203, 255, 256);
    case 7:
      return new cxform(0, 0, 0, 0, 139, 163, 215, 256);
    case 8:
      return new cxform(0, 0, 0, 0, 223, 126, 55, 256);
    case 9:
      return new cxform(0, 0, 0, 0, 184, 95, 29, 256);
    case 10:
      return new cxform(0, 0, 0, 0, 211, 24, 24, 256);
    case 11:
      return new cxform(0, 0, 0, 0, 255, 249, 174, 256);
    case 12:
      return new cxform(0, 0, 0, 0, 240, 220, 153, 256);
  }
}

export function setCanvas(newCanvas) {
  canvas = newCanvas;
}

function renderEachFrames(decodedSprites) {
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.save();
  ctx.transform(
    canvas.width / originalWidth,
    0,
    0,
    canvas.height / originalHeight,
      (-19/100) * canvas.width,
      (-34/100) * canvas.height
  );
  if (isDarkPalette) {
    ctx.filter = "contrast(1.33) blur(0.33px) brightness(0.9)";
  } else if (isShinyPalette) {
    ctx.filter = "contrast(1) blur(0.33px) brightness(1.03)";
  } else {
    ctx.filter = "contrast(1.03) blur(0.40px)";
  }

  allFrames.sprite2363(ctx, ctrans, decodedSprites, 0, time);
  ctx.restore();
}

export let canvas: HTMLCanvasElement;
export let ctx: EnhancedCanvasRenderingContext2D;
var ctrans = new cxform(0, 0, 0, 0, 255, 255, 255, 255);
var time = 0;
export var frames = [];
export var scalingGrids = {};
export var boundRects = {};
export var decodedColors = [];

var isDarkPalette = false;
var isShinyPalette = false;
var decodedSprites = [];

export var backgroundColor = "#FFEBD1";
export var originalWidth = 150;
export var originalHeight = 150;

export function renderDinozBySpriteDinoz(currentCanvas, currentCtx, spriteDinoz) {
  enhanceContext(currentCtx);
  canvas = currentCanvas;
  ctx = currentCtx as EnhancedCanvasRenderingContext2D;

  ctrans = new cxform(0, 0, 0, 0, 255, 255, 255, 255);
  scalingGrids = {};
  boundRects = {};
  time = 0;
  frames = [];
  decodedColors = [];
  isDarkPalette = spriteDinoz.indexOf("#") !== -1 || spriteDinoz.indexOf("%23") !== -1;
  isShinyPalette = spriteDinoz.indexOf("$") !== -1;

  decodedColors.push(
    getColor(decode62(spriteDinoz.substring(9, 10).charCodeAt(0)))
  );
  decodedColors.push(
    getColor(decode62(spriteDinoz.substring(10, 11).charCodeAt(0)))
  );
  decodedColors.push(
    getColor(decode62(spriteDinoz.substring(11, 12).charCodeAt(0)))
  );
  decodedColors.push(
    getColor(decode62(spriteDinoz.substring(12, 13).charCodeAt(0)))
  );
  decodedColors.push(
    getColor(decode62(spriteDinoz.substring(13, 14).charCodeAt(0)))
  );

  decodedSprites = [];
  decodedSprites.push(decode62(spriteDinoz.substring(0, 1).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(1, 2).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(2, 3).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(3, 4).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(4, 5).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(5, 6).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(6, 7).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(7, 8).charCodeAt(0)));
  decodedSprites.push(decode62(spriteDinoz.substring(8, 9).charCodeAt(0)));

  backgroundColor = "#FFEBD1";
  originalWidth = 100;
  originalHeight = 100;
  renderEachFrames(decodedSprites);
}
